const CollabEliseChalmin = require('./CollabEliseChalmin.json');
const PochesChemises = require('./PochesChemises.json');
const TrenchCoat = require('./TrenchCoat.json');
const Sweat = require('./Sweat.json');
const CustomisezVosBasketsBasiques = require('./CustomisezVosBasketsBasiques.json');
const MaillotDeBain = require('./MaillotDeBain.json');
const RetoucheDesOurletsDePantalon = require('./RetoucheDesOurletsDePantalon.json');
const RetoucheDesOurletsSurVosRideaux = require('./RetoucheDesOurletsSurVosRideaux.json');
const RetoucheDuDosNuSurLaRobeDeMariee = require('./RetoucheDuDosNuSurLaRobeDeMariee.json');
const RetoucheSurVosJeansAbimes = require('./RetoucheSurVosJeansAbimes.json');
const BroderiesCulotte = require('./BroderiesCulotte.json');
const BroderiesJean = require('./BroderiesJean.json');
const BroderiesFleuries = require('./BroderiesFleuries.json');
const BroderiesChemises = require('./BroderiesChemises.json');
const BroderieLingeDeMaison = require('./BroderieLingeDeMaison.json');
const CreezVosPropresAccessoires = require('./CreezVosPropresAccessoires.json');
const LUpcyclingQuEstCeQueCEst = require('./LUpcyclingQuEstCeQueCEst.json');
const BalzacXTilli = require('./BalzacXTilli.json');
const LesRetouchesDuCostume = require('./LesRetouchesDuCostume.json');
const CommentReparerUnTrouDeMiteDansUnPull = require('./CommentReparerUnTrouDeMiteDansUnPull.json');
const BrillezAvecSwarovski = require('./BrillezAvecSwarovski.json');
const CommentRetoucherSaRobeDeMariee = require('./CommentRetoucherSaRobeDeMariee.json');
const CommentTransformerSaRobeDeMariee = require('./CommentTransformerSaRobeDeMariee.json');
const Example = require('./Example.json');
const RobeDeMarieeBohemePasCher = require('./RobeDeMarieeBohemePasCher.json');
const RobeDeMarieeManchesDentelle = require('./RobeDeMarieeManchesDentelle.json');
const RobeCombishort = require('./RobeCombishort.json');
const RobeJupe = require('./RobeJupe.json');
const PullGilet = require('./PullGilet.json');
const ChemiseJupe = require('./ChemiseJupe.json');
const ChemiseRobe = require('./ChemiseRobe.json');
const TransformerDesVieuxVetements = require('./TransformerDesVieuxVetements.json');
const TransformerUnChemisierTropLarge = require('./TransformerUnChemisierTropLarge.json');
const TransformerUneJupeEnShortCouture = require('./TransformerUneJupeEnShortCouture.json');
const TransformerUneChemiseDHommeEnVetementDEnfants = require('./TransformerUneChemiseDHommeEnVetementDEnfants.json');
const SacEnJeanRecycle = require('./SacEnJeanRecycle.json');
const RelookezVotreSacCabasEnJeanRecycle = require('./RelookezVotreSacCabasEnJeanRecycle.json');
const FabriquezVotreSacEnTissuRecycle = require('./FabriquezVotreSacEnTissuRecycle.json');

module.exports = [
  CollabEliseChalmin,
  PochesChemises,
  TrenchCoat,
  Sweat,
  CustomisezVosBasketsBasiques,
  MaillotDeBain,
  RetoucheDesOurletsDePantalon,
  RetoucheDesOurletsSurVosRideaux,
  RetoucheDuDosNuSurLaRobeDeMariee,
  RetoucheSurVosJeansAbimes,
  BroderiesCulotte,
  BroderiesJean,
  BroderiesFleuries,
  BroderiesChemises,
  BroderieLingeDeMaison,
  CreezVosPropresAccessoires,
  LUpcyclingQuEstCeQueCEst,
  BalzacXTilli,
  LesRetouchesDuCostume,
  CommentReparerUnTrouDeMiteDansUnPull,
  BrillezAvecSwarovski,
  CommentRetoucherSaRobeDeMariee,
  CommentTransformerSaRobeDeMariee,
  Example,
  RobeDeMarieeBohemePasCher,
  RobeDeMarieeManchesDentelle,
  RobeCombishort,
  RobeJupe,
  PullGilet,
  ChemiseJupe,
  ChemiseRobe,
  TransformerDesVieuxVetements,
  TransformerUnChemisierTropLarge,
  TransformerUneJupeEnShortCouture,
  TransformerUneChemiseDHommeEnVetementDEnfants,
  SacEnJeanRecycle,
  RelookezVotreSacCabasEnJeanRecycle,
  FabriquezVotreSacEnTissuRecycle,
];
