/* eslint-disable max-len */
const laRubriquePosts = require('./json/laRubriquePosts/index');
const laRubriqueCategories = require('./json/laRubriqueCategories.json');
const landings = require('./json/landingsMains.json');
const localitiesLandings = require('./json/landingsLocalities.json');
const landingsAds = require('./json/landingsAds.json');
const landingsAdsPieces = require('./json/landingsAdsPieces.json');
const landingsAdsCurtains = require('./json/landingsAdsCurtains.json');
const { getSeo, getPath } = require('./services/seoLocalitiesFormatter');
const { getPostSeo } = require('./services/seoPostsFormatter');
const routesHome = require('./config/routes/routes.home');
const routesMyAccount = require('./config/routes/routes.myAccount');
const routesOrder = require('./config/routes/routes.order');

const routes = {
  ...routesHome,
  ...routesMyAccount,
  ...routesOrder,
};

if (process.env.GATSBY_BRAND) {
  routes.HomePage.component = routes.Step1.component;
}

laRubriquePosts.forEach((post) => {
  routes[`LaRubrique_${post.slug}`] = {
    url: post.path,
    seo: getPostSeo(post),
  };
});

laRubriqueCategories.forEach((category) => {
  routes[`LaRubriqueCategory_${category.slug}`] = {
    url: category.path,
    seo: category.seo,
  };

  (category.clothCategories || []).forEach((cloth) => {
    routes[`LaRubriqueCategory_${cloth.slug}`] = {
      url: cloth.path,
      seo: cloth.seo,
    };
  });
});

landings.forEach((landing) => {
  routes[landing.slug] = {
    url: landing.path,
    seo: landing.seo,
  };
});

localitiesLandings.forEach((landing) => {
  routes[landing.slug] = {
    url: getPath(landing),
    seo: getSeo(landing),
  };
});

landingsAds.forEach((landing) => {
  routes[landing.slug] = { url: landing.url };
});
landingsAdsPieces.forEach((landing) => {
  routes[landing.slug] = { url: landing.url };
});
landingsAdsCurtains.forEach((landing) => {
  routes[landing.slug] = { url: landing.url };
});

module.exports = routes;
