export function init() {
  if (typeof window !== 'object' || typeof window.location !== 'object') return;
  const { protocol, hostname, pathname, search } = window.location;
  window.dataLayer = [{
    original_location: `${protocol}//${hostname}${pathname}${search}`,
    user_role: 'customer',
    support: 'site',
    customer_type: 'b2c',
  }];
  window.dataLayerOrderEvents = window.dataLayerOrderEvents || [];
}

const orderEventsToTracks = [
  'Order Funnel - Category Chosen',
  'Order Funnel - Item Chosen',
  'Order Funnel - Fabric Chosen',
  'Order Funnel - Order Type Chosen',
  'Order Funnel - First Question Displayed',
  'Order Funnel - Lite - Know-How Chosen',
  'Order Funnel - Lite - Fabric Chosen',
  'Order Funnel - Needs Described',
  'Order Funnel - Promo Code Added',
  'Order Funnel - Extra Item Button Clicked',
  'Order Funnel - Time Slot Chosen',
  'Order Funnel - Higher Fees Message Displayed',
];

export function pushToLayer(event, skipDataLayerOrderEvents) {
  if (typeof window !== 'object' || typeof window.dataLayer !== 'object') return;
  window.dataLayer.push(event);
  if (orderEventsToTracks.includes(event.event) && !skipDataLayerOrderEvents) {
    if (!window.dataLayerOrderEvents) window.dataLayerOrderEvents = [];
    window.dataLayerOrderEvents.push(event);
  }
}

export function setOrderEvents(dataLayerOrderEvents = []) {
  window.dataLayerOrderEvents = dataLayerOrderEvents;
}

export function getOrderEvents() {
  return window.dataLayerOrderEvents || [];
}
