/* eslint-disable max-len */

function getLocalitySeo({ city, locality, zipcode }) {
  const localityName = locality || city;
  const completedZipcode = (zipcode.length === 2) ? `${zipcode}000` : zipcode;
  return {
    title: `Retouche ${localityName} (${completedZipcode}) : Votre Couturier à ${localityName} - Tilli`,
    description: `Retouche ${localityName} ${zipcode} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getArrondissementSeo({ city, zipcode, arrondissement }) {
  return {
    title: `Retouche ${city} ${arrondissement} : Votre Couturier à ${city} ${arrondissement} (${zipcode}) - Tilli`,
    description: `Retouche ${city} ${arrondissement} : Votre Couturier à Domicile pour toutes vos Retouches ✅Disponible 7/7 de 7h à 22h ✓Prix identique à votre Retoucherie de Quartier`,
  };
}

function getSeo(seo) {
  if (seo.arrondissement) return getArrondissementSeo(seo);
  return getLocalitySeo(seo);
}

function getPath({ city, locality, arrondissement }) {
  if (arrondissement) {
    const arrondissement2digits = (`0${arrondissement}`).slice(-2);
    return `/retouche-vetement/${city}/${city}-${arrondissement2digits}/`.toLowerCase();
  }
  if (locality) {
    return `/retouche-vetement/${city}/${locality}/`.toLowerCase();
  }
  return `/retouche-vetement/${city}/`.toLowerCase();
}

module.exports = {
  getLocalitySeo,
  getArrondissementSeo,
  getSeo,
  getPath,
};
