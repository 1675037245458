import React from 'react';
import PropTypes from 'prop-types';

import callApi, { getJwtToken } from '../services/api';

const defaultState = {
  customer: undefined,
  orders: undefined,
  selectedOrder: undefined,
};

const CustomerContext = React.createContext(defaultState);

class CustomerProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      customer: undefined,
      orders: undefined,
      selectedOrder: undefined,
    };
    this.initializeCustomerContext = this.initializeCustomerContext.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.setCustomerAndGetOrders = this.setCustomerAndGetOrders.bind(this);
    this.setSelectedOrder = this.setSelectedOrder.bind(this);
    this.addCustomerCard = this.addCustomerCard.bind(this);
    this.addCustomerAddress = this.addCustomerAddress.bind(this);
    this.addCustomerFeedbackToOrder = this.addCustomerFeedbackToOrder.bind(this);
    this.editCustomerInfos = this.editCustomerInfos.bind(this);
    this.editCustomerAddress = this.editCustomerAddress.bind(this);
    this.deleteCustomerCard = this.deleteCustomerCard.bind(this);
    this.deleteCustomerAddress = this.deleteCustomerAddress.bind(this);
    this.fetchCustomer = this.fetchCustomer.bind(this);
    this.setCustomerDiscountVouchers = this.setCustomerDiscountVouchers.bind(this);
    this.setIsShadowAccount = this.setIsShadowAccount.bind(this);
  }

  setCustomer(customer) {
    this.setState({ customer });
  }

  setIsShadowAccount() {
    this.setState({ customer: {
      ...this.state.customer,
      isShadowAccount: false,
    } });
  }

  setCustomerDiscountVouchers(discountVouchers) {
    this.setState({ customer: {
      ...this.state.customer,
      discountVouchers,
    } });
  }

  setCustomerAndGetOrders(customer) {
    this.setState({ customer });
    callApi('public/orders').then((res) => {
      this.setState({ orders: res.orders });
    });
  }

  setSelectedOrder(selectedOrder) {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
    this.setState({ selectedOrder });
  }

  initializeCustomerContext() {
    this.setState({ ...defaultState });
  }

  addCustomerCard(card) {
    const customer = { ...this.state.customer };
    customer.cards.push(card);
    this.setState({ customer: { ...customer } });
  }

  addCustomerAddress(address) {
    const customer = { ...this.state.customer };
    customer.addresses.push(address);
    this.setState({ customer: { ...customer } });
  }

  addCustomerFeedbackToOrder(orderId, customerFeedbackRating) {
    const selectedOrder = { ...this.state.selectedOrder };
    selectedOrder.customerFeedbackRating = customerFeedbackRating;
    this.setState({ selectedOrder: { ...selectedOrder } });
  }

  editCustomerInfos(infos) {
    this.setState({ customer: { ...this.state.customer, ...infos } });
  }

  editCustomerAddress(addresses) {
    const customer = { ...this.state.customer };
    this.setState({ customer: { ...customer, addresses } });
  }

  deleteCustomerCard(id) {
    const cards = this.state.customer.cards.filter(card => card.id !== id);
    this.setState({ customer: {
      ...this.state.customer,
      cards: [...cards],
    } });
  }

  deleteCustomerAddress(id) {
    const addresses = this.state.customer.addresses.filter(address => address._id !== id);
    this.setState({ customer: {
      ...this.state.customer,
      addresses: [...addresses],
    } });
  }

  fetchCustomer() {
    const jwtToken = getJwtToken();
    if (jwtToken) {
      return callApi('public/fetchMe', 'GET')
        .then(({ customer }) => {
          this.setCustomerAndGetOrders(customer);
          return customer;
        })
        .catch(() => undefined);
    }
    return Promise.resolve();
  }

  render() {
    const { children } = this.props;
    const {
      customer,
      orders,
      selectedAccountCategory,
      selectedOrder,
    } = this.state;
    const customerContext = {
      customer,
      orders,
      selectedAccountCategory,
      selectedOrder,
      setCustomer: this.setCustomer,
      setCustomerAndGetOrders: this.setCustomerAndGetOrders,
      setSelectedOrder: this.setSelectedOrder,
      addCustomerCard: this.addCustomerCard,
      addCustomerAddress: this.addCustomerAddress,
      editCustomerInfos: this.editCustomerInfos,
      editCustomerAddress: this.editCustomerAddress,
      initializeCustomerContext: this.initializeCustomerContext,
      deleteCustomerCard: this.deleteCustomerCard,
      deleteCustomerAddress: this.deleteCustomerAddress,
      addCustomerFeedbackToOrder: this.addCustomerFeedbackToOrder,
      fetchCustomer: this.fetchCustomer,
      setCustomerDiscountVouchers: this.setCustomerDiscountVouchers,
      setIsShadowAccount: this.setIsShadowAccount,
    };
    return (
      <CustomerContext.Provider value={customerContext} >
        {children}
      </CustomerContext.Provider>
    );
  }
}

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomerContext;

export { CustomerProvider };
