const trackScreen = require('./src/services/analytics').trackScreen;
require('./src/layouts/index.css');

const React = require('react');
const { OrderProvider } = require('./src/context/OrderContext');
const { CustomerProvider } = require('./src/context/CustomerContext');
const { init } = require('./src/services/googleTagManager');

init();

// eslint-disable-next-line react/prop-types
exports.wrapRootElement = ({ element }) => (
  <OrderProvider>
    <CustomerProvider>
      {element}
    </CustomerProvider>
  </OrderProvider>
);

exports.onRouteUpdate = (state) => {
  trackScreen(state.location.pathname);
};
